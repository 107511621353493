import { Auth0Provider, AppState, AuthorizationParams } from '@auth0/auth0-react';
import React, { PropsWithChildren } from 'react';
import { navigate } from 'gatsby';
import { EnvConfig } from '@env-config';

interface Auth0ProviderWithNavigateProps {
  children: React.ReactNode;
}

const auth0Audience = new EnvConfig().get('AUTH0_AUDIENCE');

export const SHARED_AUTH0_PARAMS = {
  audience: auth0Audience,
  prompt: 'login',
  scope: 'openid profile email read:account write:transaction:debit read:product_pricing',
} as AuthorizationParams;

export const Auth0ProviderWithNavigate = ({ children }: PropsWithChildren<Auth0ProviderWithNavigateProps>): React.ReactNode => {
  const envConfig = new EnvConfig();
  const domain = envConfig.get('AUTH0_DOMAIN');
  const clientId = envConfig.get('AUTH0_CLIENT_ID');
  const redirectUri = envConfig.get('AUTH0_CALLBACK_URL');
  const audience = envConfig.get('AUTH0_AUDIENCE');

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (!(domain && clientId && redirectUri)) {
    return children;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        audience: audience,
        redirect_uri: redirectUri,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};
